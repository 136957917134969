input[type="text"],
[type="email"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
  outline: 2px solid #777;
}

button[type="submit"] {
  width: 100%;
  background-color: #aaa;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #b5b5b5;
}

textarea {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid #999;
  border-radius: 4px;
  font-size: 16px;
  /* resize: none; */
}
