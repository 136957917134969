.project {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
  gap: 32px;

  .projectImg {
    width: 200px;
  }

  @media screen and (max-width: 576px) {
    flex-direction: column;
    padding-bottom: 16px;

    & + .projectImgRight {
      flex-direction: column-reverse;
    }
  }
}
