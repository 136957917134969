* {
  box-sizing: border-box;
  font-family: "VT323", monospace;
  background-color: #292929;
  color: #dedede;
}

.header {
  font-size: 48px;
  text-align: center;
  padding: 16px;
  color: #ffffff;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  gap: 32px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: normal;
  }
}

.left {
  white-space: nowrap;
  cursor: pointer;
  text-decoration: underline;
  line-height: 30px;
  padding: 16px 32px;
  margin-top: 20px;
  border: 1px solid #ffffff;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    margin: 0 32px;
    text-align: center;

    li {
      display: inline-block;
      margin: 0 16px;
    }
  }

  @media screen and (max-width: 576px) {
    li{
      display: list-item;
      font-size: 18px;
      margin-bottom: 16px;
    }
    
  }
}

.main {
  height: calc(100vh - 90px);
  overflow: auto;

  @media screen and (max-width: 768px) {
    height: auto;
    margin: 0 32px;
  }
}
